import { DateRange } from "~/components/DateRange";
import { RetreatPublic } from "~/schemas/retreat";

export const RetreatInfo = ({ retreat }: { retreat: RetreatPublic }) => {
  return (
    <>
      <h1>{retreat.title}</h1>
      <p>
        <DateRange start={retreat.start_date} end={retreat.end_date} />
      </p>

      <article
        dangerouslySetInnerHTML={{
          __html: retreat.description,
        }}
      ></article>
    </>
  );
};
